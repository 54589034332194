<template>
	<div
		class="full-height pa-20"
	>
		<MypageMaster
			v-if="user.roleGroup == 'admin'"

			:user="user"

			@setNotify="setNotify"
			@onLoad="onLoad"
		></MypageMaster>

		<MypageAgency
			v-else-if="user.roleGroup == 'agency'"

			:user="user"

			@setNotify="setNotify"
			@onLoad="onLoad"
		></MypageAgency>

		<MypageMerchant
			v-else-if="user.roleGroup == 'merchant'"

			:user="user"

			@setNotify="setNotify"
			@onLoad="onLoad"
		></MypageMerchant>
	</div>
</template>

<script>
import MypageMaster from "@/view/Management/MypageMaster";
import MypageAgency from "@/view/Management/MypageAgency";
import MypageMerchant from "@/view/Management/MypageMerchant";

export default {
	name: 'Mypage'
	,components: {MypageMerchant, MypageAgency, MypageMaster}
	,data: function(){
		return {
            user: [],
			program: {
				name: '마이페이지'
				, top: true
			}
		}
	}
	,computed: {
		item_history: function(){
			return this.items_history.filter(function(item){
				return item
			})
		}
	}
	,methods: {
		setNotify: function({ type, message }){
			this.$layout.setNotify( { type: type, message: message })
		}
		,onLoad: function(program){
            this.$layout.setNotify(program)
		}
	}
	,created() {
        this.$layout = this.$root.$children[0].$children[0]
        this.user = this.$layout.user
	}
}
</script>














