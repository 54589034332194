0.<template>
	<div
		class="full-height pa-10-20"
	>
		<h6>{{ program.name }}</h6>
		<div class="justify-space-between">
			<div
				class="flex-1 mr-10 bg-white pa-10"
			>
				<div>
					<h6 class="text-left color-identify under-line-identify">기본정보</h6>
					<table
						class="table th-left td-left"
					>
						<col width="160px"/>
						<col width="auto" />
						<col width="160px"/>
						<col width="auto" />
						<tbody>
						<tr>
							<th>ID</th>
							<td>{{ item.roleId }}</td>
							<th>비밀번호</th>
							<td class="text-right">
								<button
									class="pa-5-10 bg-identify"
									@click="isPassword"
								>비밀번호 변경</button>
							</td>
						</tr>
						<tr>
							<th>담당자명</th>
							<td>{{ item.name }}</td>
							<th>연락처</th>
							<td>{{ item.hp }}</td>
						</tr>
						</tbody>
					</table>
				</div>

				<div
					class="mt-10"
				>
					<h6 class="text-left color-identify under-line-identify">관리자 </h6>
					<table
						class="table th-left td-left"
					>
						<col width="160px"/>
						<col width="auto" />
						<tbody>
						<tr
							v-for="(role, index) in item.paynaraManagerList"
							:key="'role_' + index"
						>
							<th>{{ role.roleName }}</th>
							<td>
								<div class="justify-space-between">
									{{ role.name }}

									<button
										v-if="role.roleId != 'master'"
										class="bg-identify pa-5-10"
										@click="isUpdate(role)"
									>사용 정지</button>
								</div>
							</td>
						</tr>
						</tbody>
					</table>
				</div>
			</div>

			<div class="flex-1 bg-white pa-10">
				<h6 class="color-identify">수정 이력</h6>
				<ul
					class="top-line-identify"
				>
					<li
						v-for="(history, index) in item_history"
						:key="'history_' + index"
						class="justify-space-between pa-10"
					>
						<div class="mr-10">{{ history.date}}</div>
						<div class="flex-1">{{ history.content }}</div>
					</li>
				</ul>
			</div>
		</div>

		<Modal
			:is_modal="modal_role"
			:top="true"
			:bottom="true"

			title="계정 사용정지"
			:content="role.name + ' 계정을 사용정지 하시겠습니까?'"
			width="360px"
			content_class="pa-30-10"

			@close="close"
			@click="doUpdate"
			@cancel="close"

		></Modal>

		<Modal
			:is_modal="is_modal"
			:option="modal_option"
			:top="true"
			:bottom="true"

			title="비밀번호 변경"
			width="380px"
			content_class="pa-30-10"

			@close="close"
			@click="save"
			@cancel="close"
		>
			<Password
				v-if="is_modal"
				:item="item"
				slot="modal-content"

				@success="success"
			></Password>

		</Modal>
	</div>
</template>

<script>
import Modal from "@/components/Modal";
import Password from "@/view/Management/Password";
export default {
	name: 'MypageMaster'
	,components: {Password, Modal}
	,data: function(){
		return {
            user: [],
			program: {
				name: '마이페이지'
				, top: true
			}
			, item: {
				id: '아이디'
			}
			,role: {

			}
			,type: {
				name: ''
				,code: ''
				,value: ''
			}
			, items_history: []
			,is_modal: false
			,modal_option: {

			}
			,modal_role: false
		}
	}
	,computed: {
		item_history: function(){
			return this.items_history.filter(function(item){
				return item
			})
		}
	}
	,methods: {
		getData: async function(){
			try{
				this.$layout.onLoading()
				const result = await this.$Axios({
					method: 'get'
					,url: 'mypage/headquarter'
				})

				if(result.success){
					this.item = result.data
				}else{
					this.$layout.setNotify( { type: 'error', message: result.message})
				}
			}catch(e){
				this.$layout.setNotify( { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.$layout.offLoading()
			}
		}
		,save: async function(){
			try{
				this.$layout.onLoading()
				const result = await this.$Axios({
					method: 'post'
					,url: 'mypage/changePw'
					,data: this.item
				})

				if(result.success){
					this.$layout.setNotify( { type: 'success', message: result.message})
					this.close()
				}else{
					this.$layout.setNotify( { type: 'error', message: result.message})
				}
			}catch(e){
				this.$layout.setNotify( { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.$layout.offLoading()
			}
		}
		,isPassword: function(){
			this.is_modal = true
		}
		,close: function(){
			this.is_modal = false
			this.modal_role = false

			this.item.originPw = ''
			this.item.changePw = ''
			this.item.changePwConfirm = ''
		}
		,isUpdate: function(role){
			this.role = role
			this.modal_role = true
		}
		,doUpdate: async function(){
			try{
				this.$layout.onLoading()
				const result = await this.$Axios({
					method: 'put'
					,url: 'mypage/suspend/' + this.role.idx
				})

				if(result.success){
					await  this.getData()
					this.$layout.setNotify( { type: 'success', message: result.message})
				}else{
					this.$layout.setNotify( { type: 'error', message: result.message})
				}
			}catch(e){
				this.$layout.setNotify( { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.$layout.offLoading()
				this.close()
			}
		}
		,doUpdateFee: async function(){
			try{
				this.$layout.onLoading()
				const result = await this.$Axios({
					method: 'post'
					,url: 'mypage/changeFee'
					,data: {
						feeRate: this.type.value
						,feeType: this.type.code
					}
				})

				if(result.success){
					await  this.getData()
					this.$layout.setNotify( { type: 'success', message: result.message})
					this.close()
				}else{
					this.$layout.setNotify( { type: 'error', message: result.message})
				}
			}catch(e){
				this.$layout.setNotify( { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.$layout.offLoading()
			}
		}
		,success: function(){
			this.is_modal = false
		}
	}
	,created() {
        this.$layout = this.$root.$children[0].$children[0]
        this.user = this.$layout.user
        this.$layout.onLoad(this.program)
		this.getData()
	}
}
</script>














